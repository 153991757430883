export default {
    400: {
        title: "Pedido ruim",
        header: "@:views.errors.400.title",
        html: "Solicitação incorreta. Tente novamente mais tarde."
    },
    401: {
        title: "Encontramos um erro no login",
        header: "@:views.errors.401.title",
        html: "Entre em contato com <a href='{email}' target='_blank'>o suporte</a> ."
    },
    404: {
        title: "Página não encontrada",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Requisitos de elegibilidade",
        header: "Parece que você chegou um pouco cedo…",

        contact: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Parece que você chegou ao site antes de ser habilitado e convidado diretamente. DXC O Gear está sendo lançado em etapas, país por país e por grupos de usuários, assim que os requisitos regulatórios forem trabalhados e o inventário estiver pronto. Quando você for ativado, receberá um e-mail contendo um convite pessoal com instruções.</p><p class='text-left'> Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Suporte de equipamentos</a> para dúvidas ou problemas com seu pedido ou DXC Equipamento.</p>",

        subject: "DXC Equipamento - Elegibilidade",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            "user-disabled": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse em DXC Gear. Atualmente você não é elegível.</p><p class='text-left'> Caso tenha mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Suporte de equipamentos</a> .</p>"
            },
            "non-employees": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Atualmente, ele está disponível apenas para DXC Funcionários. Se você for um contratado da DXC, entre em contato com seu gerente patrocinador para quaisquer necessidades de equipamento.</p><p class='text-left'> Caso tenha mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Suporte de equipamentos</a> .</p>"
            },
            eligibility: {
                subject: "DXC Equipamento - Elegibilidade",
                header: "Parece que você chegou um pouco cedo...",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Parece que você chegou ao site antes de ser habilitado e convidado diretamente. DXC O Gear está sendo lançado em etapas, país por país e por grupos de usuários, assim que os requisitos regulatórios forem trabalhados e o inventário estiver pronto. Quando você for ativado, receberá um e-mail contendo um convite pessoal com instruções.</p><p class='text-left'> Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Suporte de equipamentos</a> para dúvidas ou problemas com seu pedido ou DXC Equipamento.</p>",
            },
            "eligibility-location": {
                header: "DXC Equipamento não disponível em seu país...",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear, mas neste momento não há planos de lançar o DXC Gear em seu país.</p><p class='text-left'> Para obter mais informações sobre como solicitar acessórios para PC, consulte o artigo <b><a href='https://csc.service-now.com/sp?sys_kb_id=3724e21cdb224b08d417e1994b9619e7&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=3a89aab687965d1811140f2acebb3537' target='_blank' rel='noopener'>Solicitar e substituir acessórios para PC</a></b> .</p>",
                country: {
                    BRA: {
                        header: "País não disponível",
                        html: "DXC O equipamento está temporariamente indisponível no Brasil"
                    }
                }
            },
            country: {
                header: "@:views.errors.403.header",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "not-allowed": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Atualmente, ele está disponível apenas para DXC Funcionários.</p><p class='text-left'> Caso tenha mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Suporte de equipamentos</a> .</p>"
            },
            "no-permissions": {
                header: "Sem acesso",
                subject: "Sem acesso",
                html: "Você não tem permissão para abrir esta página.",
            }
        }
    },
    500: {
        title: "Erro inesperado",
        header: "@:views.errors.500.title",
        message: "Ocorreu um erro. Tente novamente mais tarde."
    },
    code: {
        title: "Erro inesperado",
        message: "@:views.errors.code.title",
        error: "Erro {code}."
    }
}