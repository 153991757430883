import quiz from "@/quiz/equipment/index.mjs"

import pages from "./pages.mjs";
import components from "./components.mjs";

export default {
    options: quiz.options,
    types: quiz.types,
    pages,
    components,
}