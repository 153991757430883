export default {
    title: "Employee Survey",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "On a scale of 1 to 10, where 1 is very dissatisfied and 10 is very satisfied, how would you rate this service?",
        },
        service_likes: {
            label: "What did you like?",
            placeholder: ""
        },
        service_improvements: {
            label: "What can be improved?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "On a scale of 1-10, 1 being not clear at all and 10 being easy to follow, how would you rate the communications about DXC Gear?"
        },
        item: {
            setup_rating: {
                label: "On a scale of 1 to 10, where 1 is very difficult and 10 is very easy, how difficult was your \"{name}\" to install and set up?",
            },
            comments: {
                label: "Please provide any comments on {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Please be advised that your survey responses will be shared with DXC and they may follow up with you regarding your feedback.",
    button: {
        submit: {
            cta: "Submit",
            processing: "Submitting"
        }
    }
}