export default {
    error: "Houve um problema ao recuperar pedidos. Atualize seu navegador.",
    meta: {
        default: "Pedidos",
        my_orders: "Meus pedidos",
        my_gear: "Meu equipamento"
    },
    loadMore: "Carregar mais",
    noOrders: "Sem pedidos",
    order: {
        product: {
            title: "Ordem",
            tracking: "Monitorando",
            quantity: "Quantidade",
            transfer: {
                sender: "Proprietário anterior",
                receiver: "Receptor",
                transferrer: "Transferidor",
                system: "Central de Ajuda"
            },
            control: {
                cancel: {
                    cta: "Clique para cancelar",
                    tooltip: "Clique para cancelar este item",
                    message: "Cancelamos seu pedido."
                },
                cancelRequest: {
                    cta: "Solicitação de cancelamento",
                    tooltip: "Clique para enviar uma solicitação de cancelamento"
                },
                received: {
                    cta: "Marcar como recebido",
                    tooltip: "Clique para confirmar a entrega",
                    message: "Obrigado por confirmar que você recebeu seu produto."
                },
                notReceived: {
                    cta: "Marcar como não recebido",
                    tooltip: "Clique para confirmar a não entrega"
                },
                damaged: {
                    cta: "Marcar como danificado/quebrado",
                    tooltip: "Clique para criar um tíquete de suporte"
                },
                hide: {
                    cta: "Ocultar item",
                    tooltip: "Clique para ocultar o item da lista"
                },
                transfer: {
                    cta: "Transferir",
                    tooltip: "Clique para transferir o item para outro funcionário"
                }
            },
            requestToCancelInfo: "Entraremos em contato com o fornecedor para solicitar um cancelamento. Observe que esta é apenas uma solicitação e o cancelamento pode não ser possível. Assim que pudermos determinar se ele pode ser cancelado ou não, informaremos você.",
            cancelOk: "OK",
            reason: "Razão",
            orderedBy: "Ordenado por",
            model: {
                cancel: {
                    reason: {
                        label: "Motivos para cancelamento",
                        placeholder: "Motivos para cancelamento"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Enviar",
                        processing: "Enviando"
                    },
                    cancel: {
                        cta: "Cancelar"
                    }
                }
            }
        }
    },
    help: {
        text: "Tem dúvidas sobre seu pedido?<br/> Consulte nosso <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centro de Suporte</b></a> para obter ajuda",
        email: {
            subject: ""
        }
    }
}