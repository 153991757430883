export default {
    "India": "India",
    "United States of America": "United States of America",
    "United Kingdom": "United Kingdom",
    "Philippines": "Philippines",
    "Australia": "Australia",
    "Spain": "Spain",
    "Bulgaria": "Bulgaria",
    "Poland": "Poland",
    "Malaysia": "Malaysia",
    "Russian Federation": "Russian Federation",
    "Ukraine": "Ukraine",
    "Germany": "Germany",
    "Brazil": "Brasil",
    "Canada": "Canada",
    "Romania": "Romania",
    "France": "France",
    "China": "China",
    "Italy": "Italy",
    "Costa Rica": "Costa Rica",
    "Mexico": "Mexico",
    "Slovakia": "Slovakia",
    "Vietnam": "Vietnam",
    "Singapore": "Singapore",
    "Argentina": "Argentina",
    "Denmark": "Denmark",
    "Japan": "Japan",
    "Belgium": "Belgium",
    "Czech Republic": "Czech Republic",
    "Netherlands": "Netherlands",
    "Switzerland": "Switzerland",
    "Sweden": "Sweden",
    "New Zealand": "New Zealand",
    "Hungary": "Hungary",
    "Egypt": "Egypt",
    "Lithuania": "Lithuania",
    "United Arab Emirates": "United Arab Emirates",
    "Ireland": "Ireland",
    "Hong Kong": "Hong Kong",
    "Austria": "Austria",
    "Saudi Arabia": "Saudi Arabia",
    "Portugal": "Portugal",
    "Norway": "Norway",
    "Chile": "Chile",
    "Thailand": "Thailand",
    "Taiwan": "Taiwan",
    "Tunisia": "Tunisia",
    "Israel": "Israel",
    "South Africa": "South Africa",
    "Colombia": "Colombia",
    "Serbia": "Serbia",
    "Luxembourg": "Luxembourg",
    "Peru": "Peru",
    "Finland": "Finland",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Korea, Republic of",
    "Indonesia": "Indonesia",
    "Jordan": "Jordan",
    "Fiji": "Fiji",
    "Morocco": "Morocco",
    "Nigeria": "Nigeria",
    "Panama": "Panama",
    "Qatar": "Qatar",
    "Greece": "Greece",
    "Turkey": "Turkey",
    "Croatia": "Croatia",
    "Kazakhstan": "Kazakhstan",
    "Cyprus": "Cyprus"
}