export default {
    header: "Confirmar endereço e enviar pedido",
    message: "O endereço de entrega está correto?",
    tip: "Escolha <b>SIM</b> para confirmar o endereço de entrega e enviar o pedido. Escolha <b>NÃO</b> para cancelar o envio e adicionar outro endereço.",
    info: "Uma vez que o pedido foi feito, não pode ser alterado. Se o produto que você está solicitando estiver em espera ou tiver uma data de envio posterior e você estiver planejando se mudar, leve isso em consideração ao escolher o endereço de entrega. Além disso, se houver um feriado ou férias próximas e você não estiver em casa para aceitar o produto, pedimos que aguarde a realização de pedidos até que esteja em casa para receber os produtos.",
    warning: {
        message: "Por favor, verifique novamente se o seu {fields} está correto, pois a transportadora pode não conseguir entregar seu pedido ou entrar em contato com você sobre seu pedido. | Por favor, verifique novamente se os seus {fields} estão correto, pois a transportadora pode não conseguir entregar seu pedido ou entrar em contato com você sobre seu pedido.",
        name: "Name",
        street: "Street",
        phone: "Phone"
    },
    buttons: {
        yes: "Sim",
        no: "Não"
    }
}