export default {
    model: {
        email: {
            label: "E-mail",
            placeholder: "Delegar e-mail"
        },
        start_on: {
            label: "Data de início",
            placeholder: "Data para início da delegação"
        },
        end_on: {
            label: "Data final",
            placeholder: "Opcional"
        },
        accept: {
            tip: "Ao delegar sua responsabilidade de aprovação, você certifica que está assumindo a responsabilidade por quaisquer pedidos aprovados por seu delegado.",
            label: "aceito"
        }
    },
    buttons: {
        submit: {
            cta: "Confirme",
            processing: "Salvando"
        },
        close: {
            cta: "Cancelar"
        }
    },
    saved: "Salvou"
}