export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Até o momento, você encomendou tudo o que está disponível para você em DXC Gear.</p><p>Se você tiver uma pergunta específica sobre isso, clique no link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> acima e envie sua pergunta.</p>",
        default_everything_something: "<p>Os itens encomendados anteriormente não são mais exibidos.</p><p>Se você tiver uma pergunta específica sobre isso, clique no link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> acima e envie sua pergunta.</p>",
        default_partial_everything: "<p>Não há produtos adicionais para encomendar.</p>",
        default_partial_something: "<p>Os itens solicitados anteriormente não são mais exibidos.</p>",
        default_partial_noitems: "",

        ehs_everything_everything: "<p>Até o momento, você encomendou tudo o que está disponível no DXC Gear.</p><p>A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato com <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_everything_something: "<p>Os itens encomendados anteriormente não são mais exibidos.</p><p>A disponibilidade dos produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato com <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_everything: "<p>Até o momento, você encomendou tudo o que está disponível no DXC Gear.</p><p>A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato com <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_something: "<p>Os itens encomendados anteriormente não são mais exibidos.</p><p>A disponibilidade dos produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato com <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_noitems: "<p>A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato com <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",

        emea_ehs_partial_everything: "A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, visite <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> e faça uma solicitação de experiência DXC. Veja como:</p><p>Na <a href='https://dxchr.service-now.com/hrportal' target='_blank'>página inicial do Employee Connect</a>, escolha Life &commat; DXC no menu superior e, em seguida, <b>Relações com funcionários</b> &gt; <b>Ambiente de trabalho</b> &gt; <b>Solicitação de experiência DXC</b>",
        emea_ehs_partial_something: "A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, visite <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> e faça uma solicitação de experiência DXC. Veja como:</p><p>Na <a href='https://dxchr.service-now.com/hrportal' target='_blank'>página inicial do Employee Connect</a>, escolha Life &commat; DXC no menu superior e, em seguida, <b>Relações com funcionários</b> &gt; <b>Ambiente de trabalho</b> &gt; <b>Solicitação de experiência DXC</b>",
        emea_ehs_partial_noitems: "A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, visite <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> e faça uma solicitação de experiência DXC. Veja como:</p><p>Na <a href='https://dxchr.service-now.com/hrportal' target='_blank'>página inicial do Employee Connect</a>, escolha Life &commat; DXC no menu superior e, em seguida, <b>Relações com funcionários</b> &gt; <b>Ambiente de trabalho</b> &gt; <b>Solicitação de experiência DXC</b>",
        emea_ehs_everything_something: "A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, visite <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> e faça uma solicitação de experiência DXC. Veja como:</p><p>Na <a href='https://dxchr.service-now.com/hrportal' target='_blank'>página inicial do Employee Connect</a>, escolha Life &commat; DXC no menu superior e, em seguida, <b>Relações com funcionários</b> &gt; <b>Ambiente de trabalho</b> &gt; <b>Solicitação de experiência DXC</b>",
        emea_ehs_everything_everything: "A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, visite <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> e faça uma solicitação de experiência DXC. Veja como:</p><p>Na <a href='https://dxchr.service-now.com/hrportal' target='_blank'>página inicial do Employee Connect</a>, escolha Life &commat; DXC no menu superior e, em seguida, <b>Relações com funcionários</b> &gt; <b>Ambiente de trabalho</b> &gt; <b>Solicitação de experiência DXC</b>"
    }
}