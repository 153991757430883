<template>
    <div>
        <div v-if="null !== impersonation" class="mb-4">{{impersonation}}</div>
        <h4 v-else class="mb-4">{{message}}</h4>
    </div>
</template>

<script>
    export default {
        name: "HomeMessage",
        data() {

            return {
                t_path: "components.home.message",
            }
        },
        computed: {
            impersonation() {

                return null !== this.$store.getters["user/impersonatorToken"]
                    ? this.t("impersonation", {name: this.$store.getters["user/profile/firstName"]})
                    : null;
            },
            message() {

                return this.t("message", {name: this.$store.getters["user/profile/name"]})
            }
        }
    }
</script>