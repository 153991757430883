export default {
    title: "Helpdesk",
    header: "Helpdesk",

    model: {
        email: {
            label: "Email do usuário",
            placeholder: "Por favor, preencher e-mail do usuário"
        }
    },
    cta: "Enviar",
    processing: "Carregando"
}