const text = {
    countryAfterComponentsBeforeSelection: "Note your Country choice will be used as part of your shipping address to send products directly to you. If you do not see your country in the list, DXC Gear is currently not available at this time in your country.",
    countryAfterComponentsAllowedSelection: "Note your Country choice will be used as part of your shipping address to send products directly to you.",
    countryAfterComponentsLimitedSelection: "DXC Gear is currently available in your country but it is opening in stages. Unless you have received notification, you may not be able to access DXC Gear at this time in your country."
};

export default {
    title: "Welcome, {userName}!",
    description: {
        default: "Welcome to DXC Gear: the source for DXC IT accessories. DXC Gear allows you to order equipment shipped directly to your door.",
        virtualworker: "Welcome to DXC Gear: the source for DXC IT accessories. DXC Gear allows you to order equipment shipped directly to your door."
    },
    text: {
        default: "To start, please answer the following question:",
        virtualworker: "To start, please answer the following question:"
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}`
    },
    buttons: {
        back: "Back",
        next: "Next",
        submit: "Submit"
    },
    saved: "Saved"
}