export default {
    title: "Pesquisa dos funcionários",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Em uma escala de 1 a 10, onde 1 é de insatisfeito e 10  muito satisfeito, como você classificaria este serviço?",
        },
        service_likes: {
            label: "O que você gostou?",
            placeholder: ""
        },
        service_improvements: {
            label: "Em que podemos melhorar?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Em uma escala de 1-10, 1 não sendo clara e 10 sendo fácil, como você classificaria as comunicações com DXC Equipamentos?"
        },
        item: {
            setup_rating: {
                label: "Em uma escala de 1 a 10, onde 1 é muito difícil e 10 é muito fácil, quão difícil foi seu \"{name}\" para se adaptar",
            },
            comments: {
                label: "Por favor nos envie comentários {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Informamos que suas respostas na pesquisa serão compartilhadas com a DXC e poderá haver acompanhamento com relação ao seu feedback.",
    button: {
        submit: {
            cta: "Enviar",
            processing: "Enviando"
        }
    }
}