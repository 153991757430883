export default {
    country: {
        label: "País de origem",
        errors: {
            required: "Uma opção deve ser selecionada"
        },
        values: {
            BRA: {
                Brazil: "Brasil"
            }
        }
    },
    entity: {
        label: "Entidade",
        errors: {
            required: "Uma opção deve ser selecionada"
        }
    },
    location: {
        label: "Lozalização da empresa",
        errors: {
            required: "Uma opção deve ser selecionada"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Local fixo",
                description: "Home Office com um local dedicado ao escritório"
            },
            "Semi-Dedicated": {
                title: "Local temporário",
                description: "Localização temporária para acomodar as necessidades de um ambiente de trabalho doméstico"
            },
            "Floating": {
                title: "Sem local fixo",
                description: "Não há espaço fixo para o escritório na casa, mas é compartilhado com outros locais, como sala de jantar ou sala de estar"
            }
        },
        errors: {
            required: "Uma opção deve ser selecionada"
        }
    },
    priority: {
        label: "Prioridade Alta",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Docking Station"
            },
            "Keyboard/Mouse": {
                title: "Teclado/Mouse"
            },
            "Web Cam": {
                title: "WebCam"
            },
            "Headset": {
                title: "Fone de ouvido"
            },
            "Office Chair": {
                title: "Cadeira de escritório"
            },
            "Desk": {
                title: "Mesa"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Nobreak"
            },
            "Something Else": {
                title: "Outros"
            },
            "Nothing Needed": {
                title: "Não é necessário"
            }
        },
        errors: {
            requiredUnless: "Uma opção deve ser selecionada",
            requiredIf: "Uma opção deve ser selecionada",
            required: "Uma opção deve ser selecionada"
        }
    },
    priorityDetails: {
        placeholder: "Forneça detalhes"
    },
    secondaryPriority: {
        label: "Prooridade média",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Segundo Monitor"
            },
            "Docking Station": {
                title: "Docking Station"
            },
            "Keyboard/Mouse": {
                title: "Teclado/Mouse"
            },
            "Web Cam": {
                title: "WebCam"
            },
            "Headset": {
                title: "Fone de ouvido"
            },
            "Office Chair": {
                title: "Cadeira de escritório"
            },
            "Desk": {
                title: "Mesa"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Nobreak"
            },
            "Something Else": {
                title: "Outros"
            },
            "Nothing Needed": {
                title: "Não é necessário"
            }
        },
        errors: {
            requiredUnless: "Uma opção deve ser selecionada",
            requiredIf: "Uma opção deve ser selecionada",
            required: "Uma opção deve ser selecionada"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Forneça detalhes"
    },
    improveWorkspace: {
        label: "Como você melhoraria seu espaço de trabalho?",
        placeholder: "Opcional",
    },
    improveRemoteWorking: {
        label: "Tirando o equipamento, o que o ajudaria a tornar o trabalho remoto mais eficaz?",
        placeholder: "Opcional",
    },
    receivedEquipmentHeadset: {
        label: "Você recebeu um fone de ouvido sem fio ou alto-falante da DXC nos últimos 24 meses?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesHeadset: {
        label: "Você teve algum problema com os equipamentos?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Descreva o problema"
    },
    isOfficeWorker: {
        label: "Você trabalha no escritório em tempo integral?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Você recebeu um teclado e um mouse da DXC nos últimos 12 meses?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Você teve algum problema com os equipamentos?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Descreva o problema"
    },
    needWebcam: {
        label: "Você precisa de uma webcam externa para o trabalho (não a embutida em seu laptop)?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    needWebcamExternal: {
        label: "Você precisa de uma webcam USB externa (não a embutida em seu laptop)?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    needMonitor: {
        label: "Você recebeu um monitor da Empresa nos últimos 4 anos?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    receivedMonitor: {
        label: "Você recebeu algum monitor da DXC nos últimos 4 anos?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesMonitor: {
        label: "Você teve algum problema com o equipamento?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Descreva o problema."
    },
    externalMonitorsCount: {
        label: "Quantos monitores externos você conecta ao seu PC?",
        values: {
            "Not use": {name: "Não usar"},
            "One": {name: "1"},
            "Two": {name: "2"},
            ">2": {name: "Mais de dois"},
        }
    },
    monitorSupportsHdmi: {
        label: "Seu(s) monitor(es) suporta(m) conexão HDMI?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    pcSupportsHdmi: {
        label: "Seu PC tem um conector HDMI?",
        values: {
            Yes: {
                name: "Sim"
            }, No: {
                name: "Não"
            }
        }
    },
    pcCompatibleMonitorConnector: {
        label: "O seu PC tem um conector monitor compatível?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    needUsbhub: {
        label: "Você precisa de um hub USB externo para o trabalho?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    moreUsbPorts: {
        label: "Você precisa de mais portas USB A para periféricos como teclado, mouse, webcam etc. É isso que está no seu PC?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Descreva o problema"
    }
}