export default {
    unverified: "Não verificado",
    verificationLink: "Reenviar a verificação por e-mail",
    buttons: {
        delete: {
            cta: "Apagar",
            processing: "Apagando"
        }
    },
    addressDeleted: "Endereço foi apagado",
    verificationEmailSent: "Email foi enviado",
    deleteAddressConfirmation: "Você tem certeza de que deseja apagar este endereço?"
}