export default {
    title: "Ordem em nome de",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Por favor, preencha o e-mail do usuário",
            errors: {
                myself: "O e-mail deve ser diferente do seu"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Enviar",
            processing: "Enviando"
        }
    },
    error: {
        userNotExists: "{email} não existe",
        country: {
            default: "DXC O equipamento não está disponível no país do usuário no momento",
            BRA: "DXC O equipamento está temporariamente indisponível no Brasil"
        }
    }
}