export default {
    title: "Verificação de Login Necessária",
    header: "@:views.auth.confirm.title",
    message: "Para continuar, favor verificar seu e-mail enviado pela DXC com o link de verificação. Clique no link do e-mail para prosseguir.",
    messageBeforeCta: "Se você não recebeu o e-mail, clique",
    messageAfterCta: "para reenviar.",
    secondMessage: "Se você ainda não recebeu o e-mail, verifique novamente no spam ou entre em contato conosco em <a href='mailto:{email}?subject={subject}'>{email}</a>.",
    subject: "DXC Equipamentos - TFA",
    buttons: {
        cta: "aqui",
        processing: "aqui"
    },
    headerAlreadyConfirmed: "Verificação de Login",
    messageAlreadyConfirmed: "Você já confirmou seu e-mail"
}