export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>To date, you have ordered everything available to you in DXC Gear. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>If you have a specific question about this, please click on the <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> link above and submit your question.</p>",
        default_everything_something: "<p>Previously ordered items are no longer shown. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>If you have a specific question about this, please click on the <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> link above and submit your question.</p>",
        default_partial_everything: "<p>There are no additional products to order. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p>",
        default_partial_something: "<p>Previously ordered items are no longer shown. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p>",
        default_partial_noitems: "",

        ehs_everything_everything: "<p>To date, you have ordered everything available to you in DXC Gear. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_everything_something: "<p>Previously ordered items are no longer shown.  If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_everything: "<p>To date, you have ordered everything available to you in DXC Gear. If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_something: "<p>Previously ordered items are no longer shown.  If you have an accessory from DXC Gear that has broken or is no longer functional, please click the Support link above and follow the prompts to open an incident for review.</p><p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",
        ehs_partial_noitems: "<p>Availability of products is based on the EHS survey. If you need additional support, please contact <a href='mailto:{supportEmail}'>{supportEmail}</a>.</p>",

        emea_ehs_partial_everything: "<p>Availability of products is based on the EHS survey. If you need additional support, please visit <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> and raise a DXC Experience Request. Here’s how:</p><p>From the <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect home page</a>, choose Life &commat; DXC from the top menu, then <b>Employee Relations</b> &gt; <b>Workplace Environment</b> &gt; <b>DXC Experience Request</b></p>",
        emea_ehs_partial_something: "<p>Availability of products is based on the EHS survey. If you need additional support, please visit <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> and raise a DXC Experience Request. Here’s how:</p><p>From the <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect home page</a>, choose Life &commat; DXC from the top menu, then <b>Employee Relations</b> &gt; <b>Workplace Environment</b> &gt; <b>DXC Experience Request</b></p>",
        emea_ehs_partial_noitems: "<p>Availability of products is based on the EHS survey. If you need additional support, please visit <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> and raise a DXC Experience Request. Here’s how:</p><p>From the <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect home page</a>, choose Life &commat; DXC from the top menu, then <b>Employee Relations</b> &gt; <b>Workplace Environment</b> &gt; <b>DXC Experience Request</b></p>",
        emea_ehs_everything_something: "<p>Availability of products is based on the EHS survey. If you need additional support, please visit <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> and raise a DXC Experience Request. Here’s how:</p><p>From the <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect home page</a>, choose Life &commat; DXC from the top menu, then <b>Employee Relations</b> &gt; <b>Workplace Environment</b> &gt; <b>DXC Experience Request</b></p>",
        emea_ehs_everything_everything: "<p>Availability of products is based on the EHS survey. If you need additional support, please visit <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect</a> and raise a DXC Experience Request. Here’s how:</p><p>From the <a href='https://dxchr.service-now.com/hrportal' target='_blank'>Employee Connect home page</a>, choose Life &commat; DXC from the top menu, then <b>Employee Relations</b> &gt; <b>Workplace Environment</b> &gt; <b>DXC Experience Request</b></p>"
    }
}