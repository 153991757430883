export default {
    title: "Order on behalf of",
    model: {
        email: {
            label: "Email",
            placeholder: "Please fill user email",
            errors: {
                myself: "Email must differ from your"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Submit",
            processing: "Submitting"
        }
    },
    error: {
        userNotExists: "{email} does not exist",
        country: {
            default: "DXC Gear is currently unavailable in user country",
            BRA: "DXC Gear is temporarily unavailable in Brazil"
        }
    }
}