export default {
    return: {
        title: "Retornar",
        header: "Itens a serem devolvidos",
        message: "O seguinte equipamento DXC deve ser devolvido. Após o envio deste formulário, você será contatado separadamente para combinar a coleta e/ou envios. Seu gerente será informado sobre o status do seu retorno.",

        model: {
            address: {
                country: {
                    label: "País"
                }
            },
            exclude: {
                label: "Excluir do retorno"
            },
            textarea: {
                placeholder: "Motivo: aprovação do gerente, devolvido anteriormente etc."
            },
            return_note: {
                label: "Notas de Devolução",
                placeholder: "Opcional: Insira quaisquer observações sobre os itens a serem devolvidos."
            },
            additional_disposal: {
                label: "Você tem algum registro DXC físico que precise ser descartado com segurança?"
            },
            additional_item: {
                type: {
                    label: "Tipo",
                    options: {
                        chair: "Cadeira",
                        desk: "Desk",
                        "desk riser": "Riser de mesa",
                        "docking station": "Docking Station",
                        "footrest": "Apoio para os pés",
                        "headset": "Fone de ouvido",
                        "hdmi cable": "Cabo HDMI",
                        "keyboard": "Teclado",
                        "kvm switch": "KVM Switch",
                        "lamp": "Luminária",
                        "laptop riser": "Laptop Riser",
                        "monitor": "Monitor",
                        "mouse": "Mouse",
                        "other": "Outra",
                        "speakerphone": "Viva voz",
                        "usb hub": "USB Hub",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Detalhe",
                    placeholder: "Detalhe"
                },
                quantity: {
                    label: "Quantidade",
                    placeholder: "Quantidade"
                }
            }
        },

        submitted: {
            title: "Devolução enviada",
            message: "Seu retorno foi enviado. Você será contatado separadamente sobre a retirada e/ou envio do item."
        },
        nothingToReturn: {
            title: "Informações de devolução",
            message: "Neste momento, você não tem nenhum item para devolver no DXC Gear."
        },
        notEligible: {
            title: "Informações de devolução",
            message: "Neste momento, o DXC Gear não pode processar sua devolução. Entre em contato com seu gerente local ou equipe de RH sobre como devolver seus itens."
        },

        button: {
            add: {
                cta: "Adicionar item não listado"
            },
            submit: {
                processing: "Enviando",
                cta: "Enviar"
            }
        }
    }
}