const text = {
    countryAfterComponentsBeforeSelection: "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você. Se você não vir seu país na lista, DXC No momento, o Gear não está disponível em seu país.",
    countryAfterComponentsAllowedSelection: "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você.",
    countryAfterComponentsLimitedSelection: "DXC Gear está atualmente disponível em seu país, mas está abrindo em etapas. A menos que você tenha recebido uma notificação, talvez não consiga acessar DXC Gear neste momento em seu país."
};

export default {
    title: "Bem-vindo, {userName}!",
    description: {
        default: "Bem-vindo ao DXC Engrenagem: a fonte para DXC acessórios de TI. DXC O Gear permite que você solicite equipamentos enviados diretamente à sua porta.",
        virtualworker: "Bem-vindo ao DXC Engrenagem: a fonte para DXC acessórios de TI. DXC O Gear permite que você solicite equipamentos enviados diretamente à sua porta."
    },
    text: {
        default: "Para começar, responda à seguinte pergunta:",
        virtualworker: "Para começar, responda à seguinte pergunta:"
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}`
    },
    buttons: {
        back: "Voltar",
        next: "Próximo",
        submit: "Enviar"
    },
    saved: "Salvou"
}