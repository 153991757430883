export default {
    empty: "Nothing to approve",
    saved: "Approvals submitted",
    review: "",
    confirm: {
        partial: "There are still orders with pending approvals. Do you wish to continue with your current changes?"
    },
    header: {
        name: "Name",
        order: "Order",
        details: "Details",
        cta: "Approval",
        message: "Please note: All items available in DXC GEAR are centrally funded by DXC IT. You will not see a charge to your budget/cost center. While IT is managing the budget for DXC Gear, every order is a DXC expense. As the approving manager, we ask that you carefully consider each request and approve only when the item is necessary for your employee to complete their job responsibilities."
    },
    item: {
        order: {
            manager: "Manager",
            requested: "Requested by"
        },
        approve: {
            reason: {
                placeholder: {
                    default: "reason",
                    approved: "optional; business justification",
                    notapproved: "reason why request was denied"
                }
            },
            approve: {
                yes: {
                    label: "Yes"
                },
                no: {
                    label: "No"
                }
            }
        },
        prior: {
            cta: {
                load: "Prior approvals",
                loading: "Loading",
                show: "Prior approvals",
                hide: "Prior approvals"
            },
            approvals: {
                empty: "No prior approvals",
                item: {
                    status: {
                        approver: "by {approver}",
                        system: "Help Desk",
                        approved: "Approved",
                        rejected: "Not Approved",
                        unrequired: "Approval Not Required",
                        reason: "Reason",
                        transferred: "Transferred"
                    }
                }
            }
        }
    },
    cta: {
        submit: {
            text: "Submit",
            processing: "Submitting"
        }
    }
}