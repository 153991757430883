export default {
    return: {
        title: "Return",
        header: "Items to be returned",
        message: "The following DXC equipment is to be returned. After submitting this form, you will be contacted separately to arrange for pickup and/or shipments. Your manager will be informed of the status of your return.",

        model: {
            address: {
                country: {
                    label: "Country"
                }
            },
            exclude: {
                label: "Exclude from return"
            },
            textarea: {
                placeholder: "Reason: manager approval, returned previously etc."
            },
            return_note: {
                label: "Return Notes",
                placeholder: "Optional: Enter any notes regarding the items to be returned."
            },
            additional_disposal: {
                label: "Do you have any physical DXC records that need to be disposed securely"
            },
            additional_item: {
                type: {
                    label: "Type",
                    options: {
                        "chair": "Chair",
                        "desk": "Desk",
                        "desk riser": "Desk Riser",
                        "docking station": "Docking Station",
                        "footrest": "Footrest",
                        "headset": "Headset",
                        "hdmi cable": "HDMI Cable",
                        "keyboard": "Keyboard",
                        "kvm switch": "KVM Switch",
                        "lamp": "Lamp",
                        "laptop riser": "Laptop Riser",
                        "monitor": "Monitor",
                        "mouse": "Mouse",
                        "other": "Other",
                        "speakerphone": "Speakerphone",
                        "usb hub": "USB Hub",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Detail",
                    placeholder: "Detail"
                },
                quantity: {
                    label: "Quantity",
                    placeholder: "Quantity"
                }
            }
        },

        submitted: {
            title: "Return Submitted",
            message: "You return has been submitted. You will be contacted separately regarding item pick up and/or shipment."
        },
        nothingToReturn: {
            title: "Return Information",
            message: "At this time, you do not have any items to return within DXC Gear."
        },
        notEligible: {
            title: "Return Information",
            message: "At this time, DXC Gear cannot process your return. Please contact your local manager or HR team on how to return your items."
        },

        button: {
            add: {
                cta: "Add Unlisted Item"
            },
            submit: {
                processing: "Submitting",
                cta: "Submit"
            }
        }
    }
}